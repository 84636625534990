<template>
  <div style="height: inherit">
    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowLeftSidebar}"
      @click="mqShallShowLeftSidebar = false"
    />
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="3"
          >
            <div class="d-flex mt-2">
              <div>
                Exibir empresa Imediato?
              </div>
              <b-form-checkbox
                :checked="showImediato"
                class="custom-control-secondary ml-1"
                name="check-button"
                switch
                @input="showImediato = !showImediato;"
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
            </div>
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="Tipo"
              label-for="account_type"
            >
              <v-select
                v-model="dateType"
                :options="dateTypes"
                :reduce="val => val.value"
                :clearable="false"
                input-id="account-type"
                :disabled="currentRouteName === 'reports'"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <label for="begin-date">Início em</label>
            <b-form-datepicker
              id="begin-date"
              v-model="beginDate"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              locale="pt-BR"
              label-help=""
            />
          </b-col>
          <b-col
            cols="12"
            md="3"
            lg="3"
          >
            <label for="end-date">Fim em</label>
            <b-form-datepicker
              id="end-date"
              v-model="endDate"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              locale="pt-BR"
              label-help=""
            />
          </b-col>
          <b-col
            cols="12"
            md="12"
          >
            <b-row>
              <b-col
                cols="12"
                md="4"
              >
                <label for="institution-id">Instituições</label>
                <v-select
                  id="institution-id"
                  v-model="institutionId"
                  name="institution-id"
                  :options="institutionOptions"
                  label="label"
                  item-value="value"
                  item-text="label"
                >
                  <span slot="no-options">Nenhuma instituição encontrada.</span>
                </v-select>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <div class="d-flex w-100">
                  <div class="flex-grow-1">
                    <label for="physician-user-id">Médicos</label>
                    <v-select
                      id="physician-user-id"
                      v-model="physicianUserId"
                      :filter="filterPhysician"
                      name="physician-user-id"
                      :options="physicianUserOptions"
                      label="label"
                    >
                      <template #option="{ value: { name, email, crm, cpf } }">
                        <div class="font-weight-bold">
                          {{ name }}
                        </div>
                        <div>
                          <span>{{ email }}</span>
                        </div>
                        <div>
                          <span class="physician-label">CPF</span>
                          <span class="font-weight-bold"> {{ maskHiddenCpf(cpf) }}</span>
                          <span class="physician-label ml-1">CRM</span>
                          <span class="font-weight-bold"> {{ crm }}</span>
                        </div>
                      </template>
                      <span slot="no-options">Nenhum médico encontrado.</span>
                    </v-select>
                  </div>
                  <div class="pt-2 ml-2 d-flex justify-content-end align-content-end">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="btn-icon mx-1"
                      :disabled="loading"
                      @click="loadReport"
                    >
                      Buscar
                      <feather-icon
                        icon="SearchIcon"
                        size="16"
                      />
                    </b-button>

                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="btn-icon"
                      :disabled="reports.length === 0"
                      @click="downloadReport"
                    >
                      Exportar
                      <feather-icon
                        icon="DownloadIcon"
                        size="16"
                      />
                    </b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="12"
          >
            <!-- institutions -->
            <div
              v-if="reports.length === 0"
              class="m-5"
            >
              <h4 class="text-center w-100">
                Não há registros
              </h4>
            </div>
            <app-collapse
              accordion
              type="border"
            >
              <app-collapse-item
                v-for="report in reports"
                :key="report.name"
                :title="report.name"
              >
                <template v-slot:header>
                  <div class="d-flex justify-content-between w-100">
                    <h4>{{ report.name }}</h4>
                    <div class="text-right">
                      <div>
                        <span class="text-muted">Valor</span>
                        <span class="collapse-title"> {{ currency(report.amount) }}</span>
                      </div>
                      <div>
                        <span class="text-muted">Recebido</span>
                        <span class="collapse-title"> {{ currency(report.discounted_amount) }}</span>
                      </div>
                      <div>
                        <span class="text-muted">Cashback</span>
                        <span class="collapse-title"> {{ currency(report.value_cashback) }}</span>
                      </div>
                    </div>
                  </div>
                </template>
                <!-- Physician -->
                <h6 class="text-muted">
                  Unidades
                </h6>
                <app-collapse accordion>
                  <app-collapse-item
                    v-for="workplace in report.workplace"
                    :key="workplace.name"
                    class="panel-workplace"
                  >
                    <template v-slot:header>
                      <div class="d-flex justify-content-between w-100">
                        <h4>{{ workplace.name }}</h4>
                        <div class="text-right">
                          <div>
                            <span class="text-muted">Valor</span>
                            <span class="collapse-title"> {{ currency(workplace.amount) }}</span>
                          </div>
                          <div>
                            <span class="text-muted">Recebido</span>
                            <span class="collapse-title"> {{ currency(workplace.discounted_amount) }}</span>
                          </div>
                        </div>
                      </div>
                    </template>
                    <h6 class="text-muted">
                      Médicos
                    </h6>
                    <app-collapse accordion>
                      <app-collapse-item
                        v-for="physician in workplace.physicians"
                        :key="physician.name"
                        :title="physician.name"
                        class="panel-physician"
                      >
                        <template v-slot:header>
                          <div class="d-flex justify-content-between w-100">
                            <div>
                              <h5>{{ physician.name }}</h5>
                              <span class="text-muted">CPF </span>
                              <span class="collapse-title">{{ maskHiddenCpf(physician.cpf) }}</span>
                            </div>
                            <div class="text-right">
                              <div>
                                <span class="text-muted">Valor</span>
                                <span class="collapse-title"> {{ currency(physician.total_amount) }}</span>
                              </div>
                              <div>
                                <span class="text-muted">Recebido</span>
                                <span class="collapse-title"> {{ currency(physician.total_discounted_amount) }}</span>
                              </div>
                            </div>
                          </div>
                        </template>
                        <!-- Physician Duties -->
                        <h6 class="text-muted">
                          Antecipações
                        </h6>
                        <b-list-group flush>
                          <b-list-group-item
                            v-for="(duty, index) in physician.items"
                            :key="physician.name + duty.institution_id"
                            :title="duty.institution_id"
                            class="physician-group-item"
                          >
                            <div class="d-flex">
                              <div class="d-flex flex-grow-1 duty-dates text-nowrap">
                                <h1>{{ index + 1 }}</h1>
                                <div>
                                  <div class="text-right duty-date">
                                    <span class="text-muted">Início</span>
                                    <span class="collapse-title"> {{ formatDate(duty.start_date) }}</span>
                                  </div>
                                  <div class="text-right duty-date">
                                    <span class="text-muted">Fim</span>
                                    <span class="collapse-title"> {{ formatDate(duty.end_date) }}</span>
                                  </div>
                                </div>
                                <div class="d-flex ml-1">
                                  <div>
                                    <div class="text-left duty-date">
                                      <span class="text-muted">Sincronizado em</span>
                                      <span
                                        class="collapse-title"
                                        :class="{'invisible':!duty.synchronized_at}"
                                      > {{ duty.synchronized_at ? formatDate(duty.synchronized_at) : '00/00/0000 00:00' }}</span>
                                    </div>
                                    <div class="text-left duty-date">
                                      <span class="text-muted">Antecipado em</span>
                                      <span class="collapse-title"> {{ formatDate(duty.anteciped_at) }}</span>
                                    </div>
                                  </div>
                                  <div class="ml-1">
                                    <div class="text-left duty-date">
                                      <span class="text-muted">Pago em</span>
                                      <span
                                        class="collapse-title"
                                        :class="{'invisible':!duty.paid_at}"
                                      > {{ duty.paid_at ? formatDate(duty.paid_at) : '00/00/0000 00:00' }}</span>
                                    </div>
                                    <div class="text-left duty-date">
                                      <span class="text-muted">Operação</span>
                                      <span class="collapse-title"> {{ duty.operation_cod }}</span>
                                    </div>
                                  </div>
                                  <div class="ml-1">
                                    <div class="text-left">
                                      <span
                                        class="collapse-title"
                                      >
                                        <button
                                          v-if="duty.paid_at"
                                          class="btn btn-sm btn-link text-success btn-contract p-0 ml-0"
                                          @click="downloadPaymentVoucher(duty.contract_id)"
                                        >
                                          Comprovante
                                          <feather-icon
                                            icon="DownloadIcon"
                                            size="14"
                                          />
                                        </button>
                                      </span>
                                    </div>
                                  </div>
                                  <div class="ml-1">
                                    <div class="text-left">
                                      <span
                                        class="collapse-title"
                                      >
                                        <button
                                          v-if="duty.contract_id"
                                          class="btn btn-sm btn-link text-danger btn-contract p-0 ml-0"
                                          @click="downloadContract(duty.contract_id)"
                                        >
                                          Contrato
                                          <feather-icon
                                            icon="DownloadIcon"
                                            size="14"
                                          />
                                        </button>
                                      </span>
                                    </div>
                                  </div>
                                  <div class="ml-1">
                                    <div class="text-left duty-date">
                                      <span class="text-muted">Tipo</span>
                                      <span class="collapse-title">  {{ duty.person_type || 'PF' }}</span>
                                    </div>
                                  </div>
                                  <div
                                    v-if="duty.physician_company_cnpj"
                                    class="ml-1"
                                  >
                                    <div class="text-left duty-date">
                                      <span class="text-muted">Empresa</span>
                                      <span class="collapse-title">  {{ duty.physician_company_name }}</span>
                                    </div>
                                    <div class="text-left duty-date">
                                      <span class="text-muted">CNPJ</span>
                                      <span class="collapse-title">  {{ formatCnpj(duty.physician_company_cnpj) }}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="text-left">
                                <div>
                                  <span class="text-muted">Valor</span>
                                  <span class="collapse-title"> {{ currency(duty.amount) }}</span>
                                </div>
                                <div>
                                  <span class="text-muted">Recebido</span>
                                  <span class="collapse-title"> {{ currency(duty.contract_amount) }}</span>
                                </div>
                              </div>
                            </div>
                          </b-list-group-item>
                        </b-list-group>
                      </app-collapse-item>
                    </app-collapse>
                  </app-collapse-item>
                </app-collapse>
              </app-collapse-item>
            </app-collapse>
          </b-col>
        </b-row>
      </div>

    </b-card>
  </div>
</template>

<script>
import {
  format, startOfMonth, endOfMonth,
} from 'date-fns'
import Fuse from 'fuse.js'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import {
  ref, onUnmounted, computed, watch,
} from '@vue/composition-api'
import vSelect from 'vue-select'
import {
  BCard, BRow, BCol,
  BListGroup, BListGroupItem,
  BFormDatepicker, BButton, BFormGroup, BFormCheckbox,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import {
  formatCnpj,
  formatDateTime, maskCnpj, maskHiddenCpf, useRouter,
} from '@core/utils/utils'
import reportStoreModule from '../reportStoreModule'
import useReport from './useReport'

export default {
  directives: {
    Ripple,
  },
  components: {
    BFormCheckbox,
    AppCollapse,
    AppCollapseItem,
    BFormGroup,
    BCard,
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
    BFormDatepicker,
    vSelect,
    BButton,
  },
  methods: {
    formatCnpj,
    maskHiddenCpf,
  },
  setup() {
    const { route } = useRouter()
    const REPORTS_STORE_MODULE_NAME = 'app-reports'
    // Register module
    if (!store.hasModule(REPORTS_STORE_MODULE_NAME)) store.registerModule(REPORTS_STORE_MODULE_NAME, reportStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(REPORTS_STORE_MODULE_NAME)) store.unregisterModule(REPORTS_STORE_MODULE_NAME)
    })

    const formatter = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })
    const currency = value => formatter.format(value / 100)
    const formatDate = value => formatDateTime(value)
    const reports = ref([])
    const beginDate = ref(format(startOfMonth(new Date()), 'yyyy-MM-dd'))
    const endDate = ref(format(endOfMonth(new Date()), 'yyyy-MM-dd'))
    const dateType = ref('service')
    const physicianUserId = ref('')
    const institutionId = ref('')
    const physicianUserOptions = ref([])
    const institutionOptions = ref([])
    const loading = ref(true)
    const showImediato = ref(false)
    const institution = ref({
      address: {},
      duties: {
        6: '',
        12: '',
        24: '',
      },
    })
    const currentRouteName = computed(() => route.value.name)

    const dateTypes = [
      { label: 'Serviço', value: 'service' },
      { label: 'Antecipação', value: 'advanced' },
      { label: 'Pagamento', value: 'payment' },
    ]

    const getReportParams = () => ({
      begin: beginDate.value,
      end: endDate.value,
      institution: institutionId.value ? institutionId.value.value : '',
      user: physicianUserId.value ? physicianUserId.value.value.user_id : '',
      date_type: dateType.value ? dateType.value : '',
      show_imediato: showImediato.value ? 1 : 0,
    })

    const loadReport = () => {
      loading.value = true
      store.dispatch('app-reports/fetchReport', getReportParams())
        .then(response => {
          loading.value = false
          reports.value = response.data.data.institution ?? []
        })
        .catch(() => {
          loading.value = false
        })
    }
    loadReport()

    watch(() => route.value.name, () => {
      physicianUserId.value = ''
      institutionId.value = ''
      loadReport()
    })

    const downloadReport = () => {
      const params = getReportParams()
      store.dispatch('app-reports/downloadReport', params)
        .then(response => {
          const blob = new Blob([response.data], { type: response.headers['content-type'] })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = `Antecipações_${params.begin}_${params.end}.xlsx`
          link.click()
          URL.revokeObjectURL(link.href)
        })
    }

    const downloadContract = id => {
      store.dispatch('app-reports/downloadContract', { id })
        .then(response => {
          const blob = new Blob([response.data], { type: response.headers['content-type'] })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = `contrato-${id}.pdf`
          link.click()
          URL.revokeObjectURL(link.href)
        })
    }

    const downloadPaymentVoucher = contractId => {
      store.dispatch('app-reports/exportPaymentVoucher', { contractId })
        .then(response => {
          const downloadLink = document.createElement('a')
          downloadLink.target = '_blank'
          downloadLink.download = `comprovante_${contractId}.pdf`
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const URL = window.URL || window.webkitURL
          const downloadUrl = URL.createObjectURL(blob)
          downloadLink.href = downloadUrl
          document.body.append(downloadLink)
          downloadLink.click()
          document.body.removeChild(downloadLink)
          URL.revokeObjectURL(downloadUrl)
        })
    }

    store.dispatch('app-reports/fetchPhysicians', { perPage: 99999999 })
      .then(response => {
        physicianUserOptions.value = response.data.data.map(physician => ({
          value: physician,
          label: `${physician.name} | ${maskHiddenCpf(physician.cpf)}`,
        }))
      })

    store.dispatch('app-reports/fetchInstitutions', { perPage: 99999999 })
      .then(response => {
        institutionOptions.value = response.data.data.map(inst => ({
          value: inst.id,
          label: `${inst.name} | ${maskCnpj(inst.cnpj)}`,
        }))
      })

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    function filterPhysician(options, search) {
      const fuse = new Fuse(options, {
        keys: ['value.name', 'value.cpf', 'value.crm', 'value.email'],
        shouldSort: true,
      })
      return search.length
        ? fuse.search(search).map(({ item }) => item)
        : fuse.list
    }

    const {
      tableColumns,
      perPage,
      currentPage,
      totalInstitutions,
      dataMeta,
      perPageOptions,
      isSortDirDesc,
      refInstitutionListTable,

      refetchData,
      fetchInstitutions,

      updateInstitution,
      searchQuery,
      sortBy,
      phoneMask,
    } = useReport()

    return {
      showImediato,
      loading,
      dateType,
      dateTypes,
      currency,
      formatDate,
      reports,

      updateInstitution,
      searchQuery,
      fetchInstitutions,
      perfectScrollbarSettings,
      sortBy,
      loadReport,

      // Left Sidebar Responsive
      mqShallShowLeftSidebar,
      beginDate,
      endDate,
      physicianUserId,
      institutionId,
      tableColumns,
      perPage,
      currentPage,
      totalInstitutions,
      dataMeta,
      perPageOptions,
      isSortDirDesc,
      refInstitutionListTable,
      refetchData,
      institution,
      phoneMask,
      physicianUserOptions,
      institutionOptions,
      downloadReport,
      filterPhysician,
      downloadPaymentVoucher,
      currentRouteName,
      downloadContract,
    }
  },
}
</script>

<style lang="scss" scoped>
.btn-contract {
  padding: 0;
  margin-left: 3px;
  margin-top: -7px;
}
.text-muted {
  color: #898993!important;
}
.duty-date, .duty-date .collapse-title {
  font-size: 12px;
}
.physician-group-item {
  padding-top: .25rem;
  padding-bottom: .25rem;
  .collapse-title, .text-right {
    font-size: 12px;
  }
}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
@import "@core/scss/vue/libs/vue-select.scss";

.panel-physician {
  background-color: rgb(248, 248, 248);
}

label {
  margin-top: 0.2857rem!important;
}

.collapse-border .card .card-header {
  padding-top: .5rem;
  padding-bottom: .5rem;
  h4 {
    margin-bottom: 0;
  }
  div.d-flex {
    align-items: center;
  }
}

.duty-dates {
  align-items: center;
  h1 {
    color: #898993;
    font-size: 14px;
    margin-bottom: 0;
    margin-right: 10px;
  }
}

.physician-label {
  font-size: 12px;
}
</style>
